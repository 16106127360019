import React from "react";
import { Link, StaticQuery, graphql } from "gatsby";

import educatorsImage from "../../images/insight-education_educators.png";
import parentsImage from "../../images/insight-education_parents.png";

import "./targetGroups.css";

const groupNameImageMap = {
  teachers: educatorsImage,
  students: parentsImage
};
const TargetGroup = props => {
  const { name, description, buttonLabel, groupId } = props;

  const toLink = `group/${groupId}`;
  return (
    <div key={name} className="groups-card">
      <h3>{name}</h3>
      <p className="groups-description">{description}</p>
      <Link className="button " to={toLink}>
        {buttonLabel}
      </Link>
    </div>
  );
};

const TargetGroups = props => (
  <StaticQuery
    query={graphql`
      query {
        allPrismicTargetgroup {
          edges {
            node {
              uid
              data {
                priority
                name {
                  text
                }
                description {
                  text
                }
                buttonlabel {
                  text
                }
              }
            }
          }
        }
      }
    `}
    render={results => {
      const groups = results.allPrismicTargetgroup.edges
        .map(group => {
          const { uid } = group.node;
          const { priority, name, description, buttonlabel } = group.node.data;
          return {
            priority,
            name: name.text,
            description: description.text,
            buttonLabel: buttonlabel.text,
            groupId: uid
          };
        })
        .sort((a, b) => {
          // items without a priority set are placed at end of list
          if (!a.priority) return 1;
          if (a.priority > b.priority) return 1;
          if (a.priority < b.priority) return -1;
          return 0;
        });

      return (
        <div className="groups">
          {groups.map((group, idx) => {
            const imageSrc = groupNameImageMap[group.groupId] || "";
            const isEven = idx % 2 === 0;
            const pairClass = isEven
              ? "groups-card--pair groups-card--pair-reverse"
              : "groups-card--pair";

            return (
              <div className={pairClass}>
                {!isEven && (
                  <div className="groups-card">
                    <img className="groups-image" src={imageSrc} alt="" />
                  </div>
                )}
                <TargetGroup key={group.groupId} {...group} />
                {isEven && (
                  <div className="groups-card">
                    <img className="groups-image" src={imageSrc} alt="" />
                  </div>
                )}
              </div>
            );
          })}
        </div>
      );
    }}
  />
);

export default TargetGroups;
