import React from "react";

import Banner from "../components/Banner";
import Layout from "../components/Layout";
import TargetGroups from "../components/TargetGroups";

import SEO from "../components/seo";

const IndexPage = () => (
  <Layout selectedPage="home">
    <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
    <Banner />
    <TargetGroups />
  </Layout>
);

export default IndexPage;
