import React from "react";
import { Link, StaticQuery, graphql } from "gatsby";

import growthImage from "../../images/insight-education_growth_v1.png";
import backgroundImage from "../../images/background-curve.png";

import "./banner.css";

const Banner = props => (
  <StaticQuery
    query={graphql`
      query {
        prismicBanner {
          data {
            title {
              html
              text
            }
            subtitle {
              html
              text
            }
            text {
              html
              text
            }
          }
        }
      }
    `}
    render={results => {
      const { title, subtitle, text } = results.prismicBanner.data;

      return (
        <div className="banner">
          <img className="banner-background-image" src={backgroundImage} alt="" />
          <div className="banner-container">
            <img className="banner-image" src={growthImage} alt="Peaceful birds in hand" />
            <div className="banner-text-group">
              <h1 className="banner-title"> {title.text}</h1>
              <h4 className="banner-subtitle">{subtitle.text} </h4>
              <h4 className="banner-text">{text.text} </h4>
              <Link
                className="button button-primary banner-button"
                to="/programs"
              >
                Our Programs
              </Link>
            </div>
          </div>
        </div>
      );
    }}
  />
);

export default Banner;
